export default function (id) {
  let window = id ? `business.invoice.${id}` : 'business.invoice'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Fatura' + (id ? ` #${id}` : ''),
    width: '1000',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window erp-invoice-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id
    }
  }, () => import('./Invoice.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          this.load && this.load()
        },
      })
    }) // return wid
}
